import { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useMediaQuery } from 'react-responsive'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { navigate } from 'gatsby'
import {
  Address,
  Association,
  useAddAssociationMutation,
} from 'manage-tritag/services/api/endpoints/associations'
import { useGetSubMastersQuery } from 'manage-tritag/services/api/endpoints/sub-masters'
import LocationPicker from 'manage-tritag/components/location-picker'
import { noLimitParams } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'
import {
  FormContent,
  SecondGrid,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
  StyledTextFieldLess,
  UploadContainer,
  TripleFormContent,
  LogoContainer,
} from './styles'
import uploadImage from '../../../images/upload.png'

const AssociationCreateForm = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const [createAssociation, { isLoading: createLoading }] =
    useAddAssociationMutation()
  const { data: subMasters, isLoading } = useGetSubMastersQuery(noLimitParams)
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      assocName: '',
      abn: '',
      primaryContact: '',
      secondaryContact: '',
      primaryEmail: '',
      secondaryEmail: '',
      primaryNumber: '',
      secondaryNumber: '',
      subMaster: '',
      posId: '',
      bank: {
        accountName: '',
        bsb: '',
        accNo: '',
      },
      school: '',
      gstRegistered: '',
    },
  })
  const [address, setAddress] = useState<Address | undefined>(undefined)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [logo, setLogo] = useState<any>(undefined)

  const handleFileClick = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const handleFileChange = (e: any) => {
    setLogo(URL.createObjectURL(e.target.files[0]))
    setSelectedFile(e.target.files[0])
  }

  const onSubmit: SubmitHandler<Association> = async (createData: any) => {
    const fileData = new FormData()
    const reqData = {
      name: createData.assocName,
      subMaster: createData.subMaster,
      primaryContact: {
        name: createData.primaryContact,
        email: createData.primaryEmail,
        phone: createData.primaryNumber,
      },
      secondaryContact: {
        name: createData.secondaryContact,
        email: createData.secondaryEmail,
        phone: createData.secondaryNumber,
      },
      address,
      abn: createData.abn,
      posId: createData.posId,
      school: createData.school,
      gstRegistered: createData.gstRegistered,
      logo: '',
      bank: createData.bank,
      status: 'Approved',
    }
    fileData.append('formData', JSON.stringify(reqData))
    fileData.append('file', selectedFile!)
    await createAssociation(fileData).unwrap()
    reset()
    toast.success('Association added succesfully.')
    navigate('/associations')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={4}>
          {logo ? (
            <LogoContainer
              onClick={handleFileClick}
              sx={{ marginBottom: '3.7rem' }}
            >
              <img
                src={logo}
                height="200"
                alt="logo"
                placeholder="blurred"
                style={{
                  height: '180px',
                  width: '180px',
                  objectFit: 'cover',
                }}
              />
            </LogoContainer>
          ) : (
            <UploadContainer onClick={handleFileClick}>
              <Typography
                variant="subtitle2"
                component="p"
                style={{ marginBottom: '0.8rem', fontWeight: 'bold' }}
              >
                Upload your logo
              </Typography>
              <Typography variant="subtitle2" style={{ fontSize: '0.8rem' }}>
                PNG and JPG file
              </Typography>
              <Typography
                variant="subtitle2"
                component="h6"
                style={{ fontSize: '0.8rem' }}
              >
                format only
              </Typography>
              <img
                height="50"
                src={uploadImage}
                alt=""
                style={{ marginTop: '1rem' }}
              />
            </UploadContainer>
          )}

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </Grid>

        <SecondGrid item xs={12} sm={12} md={8}>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#008174',
              marginBottom: '1rem',
              marginTop: isMobile ? '2rem' : '0',
            }}
          >
            Association details
          </Typography>

          <FormContent>
            <Controller
              name="assocName"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 1 }}
                  id="filled-basic"
                  label="Name"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Submaster
              </InputLabel>
              <Controller
                name="subMaster"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 2 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {!isLoading &&
                      subMasters &&
                      subMasters.map(subMaster => (
                        <MenuItem key={subMaster._id} value={subMaster._id}>
                          {subMaster.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </FormContent>

          <FormContent>
            <Controller
              name="abn"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 3 }}
                  id="filled-basic"
                  label="ABN"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <LocationPicker tabIndex={4} setAddress={setAddress} />
          </FormContent>
        </SecondGrid>
        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <Controller
              name="primaryContact"
              control={control}
              render={({ field }) => (
                <StyledTextFieldLess
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 5 }}
                  id="filled-basic"
                  label="Primary contact"
                  variant="filled"
                  size="small"
                  required
                  {...field}
                />
              )}
            />

            <Controller
              name="primaryEmail"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 6 }}
                  id="filled-basic"
                  label="Primary contact email"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />

            <Controller
              name="primaryNumber"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 7 }}
                  id="filled-basic"
                  label="Primary contact number"
                  variant="filled"
                  size="small"
                  {...field}
                  required
                />
              )}
            />
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <Controller
              name="secondaryContact"
              control={control}
              render={({ field }) => (
                <StyledTextFieldLess
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 8 }}
                  id="filled-basic"
                  label="Secondary contact"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />

            <Controller
              name="secondaryEmail"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 9 }}
                  id="filled-basic"
                  label="Secondary contact email"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />

            <Controller
              name="secondaryNumber"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 10 }}
                  id="filled-basic"
                  label="Secondary contact number"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <Controller
              name="bank.accountName"
              control={control}
              render={({ field }) => (
                <StyledTextFieldLess
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 11 }}
                  id="filled-basic"
                  label="Bank account name"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />

            <Controller
              name="bank.bsb"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 12 }}
                  id="filled-basic"
                  label="BSB"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />

            <Controller
              name="bank.accNo"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 13 }}
                  id="filled-basic"
                  label="Account number"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <Controller
              name="posId"
              control={control}
              render={({ field }) => (
                <StyledTextFieldLess
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 14 }}
                  id="filled-basic"
                  label="POS ID"
                  variant="filled"
                  size="small"
                  {...field}
                />
              )}
            />

            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                School *
              </InputLabel>
              <Controller
                name="school"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 15 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                    required
                  >
                    <MenuItem value="true">
                      <em>True</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>False</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                GST Registered *
              </InputLabel>
              <Controller
                name="gstRegistered"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 15 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    size="small"
                    {...field}
                    required
                  >
                    <MenuItem value="true">
                      <em>True</em>
                    </MenuItem>
                    <MenuItem value="false">
                      <em>False</em>
                    </MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </TripleFormContent>
        </SecondGrid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/associations')
            }}
            style={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: '83px' }}
          >
            {createLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default AssociationCreateForm
